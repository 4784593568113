<template>
  <div class="app-container h">
    <div class="v no-flex" style="width: 320px; margin-right: 20px;">
      <div class="scroll-able flex" style="border: 1px solid #DCDFE6; border-radius: 4px;">
        <el-tabs v-model="query.addressType" type="card" stretch style="width: 320px;">
          <el-tab-pane label="自定义区域" name="area">
            <dept-select v-model="query.treeNodeId" :type.sync="query.treeNodeType" v-if="query.addressType=='area'" @change="toQuery" />
          </el-tab-pane>
          <el-tab-pane label="标准行政区域" name="standard">
            <province-select v-model="query.treeNodeId" :type.sync="query.StandardAreaGroup" v-if="query.addressType=='standard'" @change="toQuery" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="flex v">
      <el-form class="head-container e" label-position="top">
        <el-form-item label="商品范围" class="filter-item">
          <quick-select url="api/brand" v-model="query.brandIds" filterable clearable placeholder="请选择品牌" style="width: 250px;" multiple />
          <tree-picker v-model="query.seriesId" :params="{brandIds:query.brandIds}" url="api/series/tree" clearable placeholder="请选择系列" style="width: 150px;" v-if="query.brandIds" />
          <tree-picker v-model="query.categoryId" url="api/category/tree" clearable placeholder="请选择分类" style="width: 150px;" />
        </el-form-item>
        <el-form-item label="指定商品" class="filter-item">
          <el-tooltip content="点击选择商品" placement="bottom">
            <div slot="content" v-if="query.goods && query.goods.length" style="max-width: 320px;">
              <template v-for="(g, i) in query.goods">{{g.name}}{{i < query.goods.length - 1 ? "、" : ""}}</template>
            </div>
            <sku-selector request-url="api/goods/sku" button-type :button-text="queryGoodsName" @submit="handleGoodsSelect">
              <i class="el-icon-circle-close padding-0-10" @click.stop="query.goods = null" v-if="query.goods && query.goods.length" />
            </sku-selector>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="汇总级别" class="filter-item">
          <el-select v-model="query.goodsGroup" placeholder style="width: 150px;">
            <el-option value="brandId" label="品牌"></el-option>
            <el-option value="seriesId" label="系列"></el-option>
            <el-option value="goodsCategoryId" label="分类"></el-option>
            <el-option value="skuId" label="商品"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item class="filter-item">
          <el-button type="success" icon="el-icon-search" @click="toQuery" :loading="loading" :disabled="!searchable">统计</el-button>
          <el-button type="success" @click="toDownload" :disabled="!searchable" icon="el-icon-download" :loading="downloadLoading">导出</el-button>
        </el-form-item>
      </el-form>
      <div class="flex scroll-able">
        <el-collapse :value="['chart', 'data']">
          <el-collapse-item title="分析图表" name="chart">
            <div v-show="store && store.length">
              <form-info-item label="显示图表前：" label-width="auto" label-position="left" content-style="padding: 0;" class="filter-item">
                <el-input-number v-model="showRankNum" size="mini" :min="1" :max="1000" :step="1" :precision="0" controls-position="right" @change="draw" />&nbsp;名
              </form-info-item>
            </div>
            <el-row :gutter="20">
              <el-col :span="12">
                <div ref="gdBarChart"></div>
              </el-col>
              <el-col :span="12">
                <div ref="gdPieChart"></div>
              </el-col>
            </el-row>
            <div class="fc-g ta-c" v-if="!store || !store.length">没有分析数据，请更改统计分析条件或稍候重试。</div>
          </el-collapse-item>

          <el-collapse-item title="数据表格" name="data">
            <template v-if="store && store.length">
              <el-table v-loading="loading" row-key="id" :data="data" size="small" border height="530">
                <el-table-column type="index" />
                <template v-for="(c, k) in columns">
                  <el-table-column :key="k" :prop="k" :label="c.label" :formatter="c.formatter" v-if="c.show" />
                </template>
                <el-table-column width="150px">
                  <div slot="header" class="h r" slot-scope="scope">
                    <el-popover trigger="click">
                      <div class="v">
                        <el-checkbox v-for="(c, k) in columns" :key="k" v-model="c.show">{{c.label}}</el-checkbox>
                      </div>
                      <i class="fa fa-ellipsis-v" slot="reference"></i>
                    </el-popover>
                  </div>
                  <div class="row-commands" slot-scope="scope"></div>
                </el-table-column>
              </el-table>
              <el-pagination align="center" :current-page.sync="page" :page-sizes="[2.5,10,20, 50]" :page-size.sync="size" layout="total, sizes, prev, pager, next, jumper" :total="store.length" />
            </template>
            <div class="fc-g ta-c" v-else>没有分析数据，请更改统计分析条件或稍候重试。</div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import deptSelect from "./select/deptSelect";
import provinceSelect from "./select/provinceSelect";
import skuSelector from "@/views/assembly/skuSelect";
import request from "@/utils/request";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
export default {
  name: "trend",
  components: {
    deptSelect,
    skuSelector,
    provinceSelect,
  },
  data() {
    let now = new Date();
    return {
      loading: false,
      goodsSpuName: null,
      store: [],
      storeTotal: 0,
      page: 1,
      size: 10,
      showRankNum: 5,
      downloadLoading: false,
      columns: {
        goodsName: { label: "商品", show: true },
        goodsCode: {
          label: "编码",
          show: true,
        },
        goodsCount: { label: "数量", show: true },
        goodsPrice: { label: "金额", show: true, formatter: this.$price },
      },
      query: {
        addressType: "area",
        StandardAreaGroup: null,
        treeNodeId: null,
        treeNodeType: null,
        dateRange: [
          new Date(
            now.getFullYear(),
            now.getMonth() - 1,
            now.getDate()
          ).getTime(),
          new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            23,
            59,
            59
          ).getTime(),
        ],
        warehouseGroup: null,
        goodsGroup: "brandId",
        goods: null,
        skuId: null,
        brandIds: null,
        seriesId: null,
        categoryId: null,
        page: 0,
        size: 10000,
      },
      chart: {
        gdBarChart: null,
        gdPieChart: null,
      },
    };
  },

  computed: {
    searchable() {
      return this.query.goodsGroup;
    },
    data() {
      return (this.store || []).slice(
        (this.page - 1) * this.size,
        this.page * this.size
      );
    },
    queryGoodsName() {
      if (this.query.goods && this.query.goods.length) {
        let str = this.query.goods[0].name;
        if (this.query.goods.length > 1)
          str += "等" + this.query.goods.length + "个商品";
        return str;
      }
      return "选择商品";
    },
  },
  methods: {
    handleGoodsSelect(res) {
      if (res) {
        this.query.goods = res;
      }
    },

    makeParams() {
      let data = JSON.parse(JSON.stringify(this.query));
      if (this.query.addressType == "standard") {
        if (this.query.StandardAreaGroup == 0) {
          data.StandardAreaGroup = "country";
        }
        if (this.query.StandardAreaGroup == 1) {
          data.StandardAreaGroup = "province";
        }
        if (this.query.StandardAreaGroup == 2) {
          data.StandardAreaGroup = "city";
        }
        if (this.query.StandardAreaGroup == 3) {
          data.StandardAreaGroup = "district";
        }
        delete data.treeNodeType;
      } else {
        data.treeNodeType = this.query.treeNodeType;
        delete data.StandardAreaGroup;
      }
      if (data.goods && data.goods.length)
        data.skuIds = data.goods.map((o) => o.id);
      delete data.goods;
      delete data.dateRange;
      return data;
    },
    toQuery() {
      let data = this.makeParams();
      this.loading = true;
      request({
        url: "@host:analysis;api/analy/stock/count",
        method: "get",
        params: data,
      })
        .then((res) => {
          this.store = res.content;
          this.storeTotal = res.totalElements;
          this.$nextTick((_) => {
            this.draw();
          });
        })
        .catch((err) => {
          this.store = [];
        })
        .finally((_) => {
          this.loading = false;
        });
    },
    handleAddGoods(list) {
      if (list) {
        this.query.skuId = list.spu.id;
        this.goodsSpuName = list.spu.name;
      }
    },
    handleDelete(e) {
      if (!this.goodsSpuName) {
        this.query.skuId = null;
      }
    },
    draw() {
      this.drawGdBarChart();
      this.drawGdPieChart();
    },
    drawGdBarChart() {
      if (this.store && this.store.length > 0) {
        if (!this.chart.gdBarChart) {
          this.chart.gdBarChart = echarts.init(this.$refs.gdBarChart, "light", {
            height: 420,
          });
        }
        let xName = [],
          xData = [],
          yName = [],
          svgp = [],
          seriesData = [];
        yName.push("件");
        xName.push("数量");
        svgp.push("数量");
        (this.store || []).forEach((o) => {
          xData.push(
            o.goodsName.length > 10 ? o.goodsName.slice(0, 10) : o.goodsName
          );
          seriesData.push(o.goodsCount);
        });
        this.chart.gdBarChart.clear();
        this.chart.gdBarChart.setOption({
          title: {
            text: "库存数量前" + this.showRankNum,
            left: "center",
          },
          color: ["#3398DB"],
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          grid: {
            y2: 150,
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              data: xData.slice(0, this.showRankNum),
              axisLabel: {
                interval: 0,
                formatter: function (value) {
                  return value.split("").join("\n");
                },
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              name: yName,
            },
          ],
          series: [
            {
              name: xName,
              type: "bar",
              data: seriesData.slice(0, this.showRankNum),
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                    position: "top",
                    textStyle: {
                      color: "black",
                      fontSize: 12,
                    },
                  },
                },
              },
            },
          ],
        });
      } else {
        this.chart.gdBarChart = null;
      }
    },
    drawGdPieChart() {
      if (this.store && this.store.length > 0) {
        if (!this.chart.gdPieChart) {
          this.chart.gdPieChart = echarts.init(this.$refs.gdPieChart, "light", {
            height: 420,
          });
        }
        let xName = [];
        xName.push("数量");
        let newArray = this.store.map((o) => {
          return {
            name: o.goodsName,
            value: o.goodsCount == 0 ? null : o.goodsCount,
          };
        });

        this.chart.gdPieChart.clear();
        this.chart.gdPieChart.setOption({
          title: {
            text: "汇总分析前" + this.showRankNum,
            left: "center",
          },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)",
          },
          series: [
            {
              name: xName,
              type: "pie",
              radius: "55%",
              center: ["50%", "60%"],
              data: newArray.slice(0, this.showRankNum),
              itemStyle: {
                normal: {
                  label: {
                    show: false,
                  },
                  labelLine: {
                    show: false,
                  },
                },
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        });
      } else {
        this.chart.gdPieChart = null;
      }
    },
    toDownload() {
      let params = this.makeParams();
      this.downloadLoading = true;
      download(
        "@host:analysis;api/analy/stock/download/stockGoodsCount",
        params
      )
        .then((result) => {
          downloadFile(result, "库存商品数量", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>

